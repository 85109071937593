import { production } from '../utils';

var selectedDate = null;
var programSelectedCount = 0;
var toolTipStatus = true;
var toolTipOpen = false;

$(document).ready(function ($) {
    $('input[type="checkbox"]').change(function () {
        $(`input[name="lunchBreak-${this.name.split('-')[0]}"]`).prop('checked', true);
    });

    // Apply an active border to the current card and grey out others by hour and date
    $('.addToSchedule').click(function (e) {
        var parentContainer = $(this).parent().parent().parent().parent().prop('id');
        $(`#${parentContainer} .inner`).each(function (index) {
            $(this).css({ 'border-color': 'transparent', opacity: '0.5' });
        });
        $(this).closest('.inner').css({ 'border-color': '#E056FD', opacity: '1' });

        if ($(this).is(':not(:checked)')) {
            $(`#${parentContainer} .inner`).each(function (index) {
                $(this).css({ 'border-color': 'transparent', opacity: '1' });
            });
        }

        $('.talk-popup').css({ opacity: '0' });
    });

    // Make it so only one checkbox is selected
    $('input[type="checkbox"]').on('change', function () {
        if ($(this).is(':checked')) {
            $(':checked[name="' + $(this).prop('name') + '"]')
                .not($(this))
                .prop('checked', false);
        }
        $('#selectedCount').html($('input[type="checkbox"]:checked.addToSchedule').length);
        programSelectedCount = document.querySelectorAll(
            'input[type="checkbox"]:checked.addToSchedule'
        ).length;
        if (programSelectedCount === 0) {
            $('#downloadItinerary').css('background', '#d7dde6');
        } else {
            $('#downloadItinerary').css('background', '#E056FD');
        }
    });

    $('.addToSchedule').hover(
        function () {
            // Will not show tooltip if it has already been showned.
            if (toolTipStatus === true && toolTipOpen === false) {
                // Sets the top by getting the element heights
                if (jQuery(window).width() < 768) {
                    $(this).parent().next().css('left', '0px');
                    // Add check to see which is bigger the parent div or popup div
                    if (
                        $(this).parent().parent().parent().height() >
                        $(this).parent().next().height()
                    ) {
                        $(this)
                            .parent()
                            .next()
                            .css(
                                'top',
                                '-' + ($(this).parent().parent().parent().height() - 30) + 'px'
                            );
                    } else {
                        $(this)
                            .parent()
                            .next()
                            .css('top', '-' + ($(this).parent().next().height() + 30) + 'px');
                    }
                } else {
                    // 30 is padding, 8 is half of the squre box width, and devide by 2 to get center
                    $(this)
                        .parent()
                        .next()
                        .css('left', ($(this).parent().parent().width() - 30 + 8) / 2);
                    // Add check to see which is bigger the parent div or popup div
                    if (
                        $(this).parent().parent().parent().height() >
                        $(this).parent().next().height()
                    ) {
                        $(this)
                            .parent()
                            .next()
                            .css(
                                'top',
                                '-' + ($(this).parent().parent().parent().height() - 30) + 'px'
                            );
                    } else {
                        $(this)
                            .parent()
                            .next()
                            .css('top', '-' + ($(this).parent().next().height() + 30) + 'px');
                    }
                }
                $(this).parent().next().css('opacity', '1');
                $(this).parent().next().css('pointer-events', 'all');
                toolTipOpen = false;
            }
        },
        function () {
            toolTipOpen = true;
        }
    );

    $('.hideToolTip').click(function (e) {
        $(e.target).parent().parent().css('opacity', '0');
        $(e.target).parent().parent().css('pointer-events', 'none');
        toolTipStatus = false;
        toolTipOpen = false;
    });

    $('.program-date-header').click(function () {
        if (selectedDate) {
            $(selectedDate).removeClass('selected');
            $('.schedule-' + $(selectedDate).data('day-id')).css('display', 'none');
        }

        selectedDate = this;

        $(this).addClass('selected');
        $('.schedule-' + $(this).data('day-id')).css('display', 'block');
    });

    if ($('.program-date-header').length > 0) {
        $('.program-date-header')[0].click();
    }

    $('#talk-description-modal').on('show.bs.modal', function (event) {
        var talk = $(event.relatedTarget).parent().parent(); // Element that triggered the modal
        var modal = $(this);

        var data = talk.find('#modal-data').data('talk');
        var isBreak = talk.find('#modal-data').data('isbreak');

        var stream = talk.find('.stream-container');
        var nameAndLocation = talk.find('.talk-bottom-container');

        // Remove previous content
        cleanPreviousModalContent(modal);

        // Change Title
        modal.find('.modal-title').html(data.talk_title);

        // Change Description
        modal.find('.talk-description').html(data.talk_desc);

        // Change Difficulty
        modal
            .find('.talk-difficulty')
            .html(
                "<span class='icon-difficulty me-2 align-self-center'></span> TECHNICAL DIFFICULTY: " +
                    data.talk_technical_difficulty
            );

        // Append the speaker names and location to the modal header
        nameAndLocation.clone().appendTo(modal.find('.modal-title-stream'));

        // If this talk has a "stream", append the badge to the modal header
        appendBadgeToHeader(talk, stream, modal);

        // Display a video of this talk (in case it exists)
        toggleVideo(data, modal);

        // Display / hide the body depending on whether this is a break or not
        toggleBody(isBreak, modal);
    });

    $('#talk-description-modal').on('hidden.bs.modal', function () {
        $(this)
            .find('.talk-video iframe')[0]
            .contentWindow.postMessage(
                '{"event":"command","func":"' + 'stopVideo' + '","args":""}',
                '*'
            );
    });
});

function cleanPreviousModalContent(modal) {
    modal.find('.modal-title-stream .stream-container').remove();
    modal.find('.modal-title-stream .talk-bottom-container').remove();
}

function appendBadgeToHeader(talk, stream, modal) {
    if (talk.find('.stream-container').length) {
        stream.clone().prependTo(modal.find('.modal-title-stream'));
    }
}

function toggleBody(isBreak, modal) {
    if (Boolean(isBreak)) {
        modal.find('.modal-body').hide();
    } else {
        modal.find('.modal-body').show();
    }
}

function toggleVideo(data, modal) {
    if (data.youtube_link) {
        modal.find('.talk-video iframe').attr('src', data.youtube_link);
        modal.find('.talk-video > div').removeClass('d-none');
        modal.find('.talk-video > p').addClass('d-none');
    } else {
        modal.find('.talk-video > div').addClass('d-none');
        modal.find('.talk-video > p').removeClass('d-none');
    }
}

function capitalize(string) {
    if (string == undefined) {
        return;
    }
    return string.toLowerCase().replace(/\b./g, function (a) {
        return a.toUpperCase();
    });
}

function updateCount(number) {
    !production() && console.log(number);
    document.getElementById('selectedCount').innerHTML = number;
}

var downloadSchedule = document.getElementById('downloadFullSchedule');
var itineraryForm = document.getElementById('itineraryForm');

// Download Full Program Button
if (downloadSchedule) {
    downloadSchedule.onclick = async () => {
        let schedule = document.getElementById('hiddenSchedule');
        let hidden = document.getElementById('pageSchedule');

        // Clear the previous schedule just incase they want to generate multiple schedules
        schedule.innerHTML = '';
        hidden.innerHTML = '';

        let talkFrame = document.querySelectorAll('.talk-frame');
        let downloadBtn = document.getElementById('downloadFullSchedule');
        let checkboxes = document.querySelectorAll('input[type="checkbox"]');
        let pdf = new jsPDF('p', 'mm', 'a4', true);
        let pdfHeight = 742.81; // Size of an A4 PDF (https://www.papersizes.org/a-sizes-in-pixels.htm) @ 72 PPI
        let elementsHeight = 0; // Height of elements on that page
        let pageCount = 0; // Count Pages
        let olddate = '';
        let morningAfternoon = '';
        let firstPage = true;

        // Disable Button
        downloadSchedule.disabled = true;

        // Insert Spinner
        downloadBtn.innerHTML =
            '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>';

        for (box of checkboxes) {
            box.checked = false;
        }

        for (frame of talkFrame) {
            !production() && console.log(frame);
            let dateAndTime = frame.querySelector('.time').innerHTML?.split(' ');
            let title = frame.querySelector('.talk-title')?.innerText;
            let speaker = frame.querySelector('.talk-speaker')?.innerText;
            let location = frame.querySelector('.stream-container')?.innerText?.split('•');
            let badges = frame.querySelector('.badges')?.innerText;
            let programTitle = frame.querySelector('.schedule-title')?.innerText;

            if (dateAndTime) {
                // Insert Date Header
                let date = dateAndTime[0];
                let time = dateAndTime[1];
                let ampm = dateAndTime[2];

                // Insert Parent Date
                if (morningAfternoon != ampm) {
                    morningAfternoon = ampm;
                    if (morningAfternoon == 'pm') {
                        schedule.innerHTML += `<tr class="table-header"><th class="time afternoon font-degular">AFTERNOON</th></tr>`;
                    }
                }

                // Insert Parent Date
                if (olddate != date) {
                    olddate = date;
                    schedule.innerHTML +=
                        '<section class="header-pdf parent-date"> <div><img src="/wp-content/uploads/2022/02/auscert-2022-light.svg"></div><div><p class="font-degular">CONFERENCE 2022</p><h3 class="font-degular">Rethink, Reskill, Reboot.</h3><h4 class="font-degular">10 - 13 May 2022 / The Star Hotel, Gold Coast</h4></div></section>';
                    schedule.innerHTML += `<h4 class="day-header font-degular">ITINERARY ${date} - ${programTitle}</h4>`;
                    schedule.innerHTML += `<tr class="table-header"><th class="time font-degular morning">MORNING</th></tr>`;
                    schedule.innerHTML +=
                        '<tr class="table-header"><th class="time font-degular">Time</th> <th class="session font-degular">Session</th> <th class="location font-degular">Location</th></tr>';
                }

                // Inserts Table Row & Columns
                if (title) {
                    schedule.innerHTML += `<tr class="table-body font-degular"><td class="time">${
                        time + ' ' + ampm
                    }</td> <td class="session">Topic:<br> <p>${title}</p> <br> Category:<br> <p>${capitalize(
                        badges
                    )}</p> <br> Speakers:<br> <p>${capitalize(
                        speaker
                    )}</p> </td> <td class="location">${capitalize(location[1])}</td> </tr>`;
                } else {
                    schedule.innerHTML += `<tr class="table-body font-degular"><td class="time">${
                        time + ' ' + ampm
                    }</td> <td class="topic">End of AM tutorials • Lunch break</td> <td></td></tr>`;
                }
            }

            // Remove styling if they have selected a custom itinerary before
            if (frame.children) {
                frame.children[0].style.opacity = '1';
                frame.children[0].style.borderColor = 'transparent';
            }
        }

        for (timeslot of schedule.children) {
            // Assigining the height of the div elements height
            elementsHeight += parseInt(timeslot.clientHeight);

            // Checks if the element is a date adding new page
            if (timeslot.classList.contains('parent-date')) {
                if (firstPage == false) {
                    hidden.innerHTML += `<section class="footer-pdf" style="margin-top: ${
                        pdfHeight - document.getElementById('pageSchedule').offsetHeight
                    }px"><h4>AUSCERT</h4><p>conference@auscert.org.au</p></section>`;
                    await html2canvas(hidden, { scale: 4 }).then((canvas) => {
                        !production() && console.log(`Saving Page ${pageCount}`);
                        const img = canvas.toDataURL('image/png');
                        pdf.addImage(img, 'PNG', 0, 0, 210, (canvas.height * 210) / canvas.width);
                        pdf.addPage();
                    });

                    elementsHeight = 0;
                    pageCount++;
                    hidden.innerHTML = '';
                }
                firstPage = false;
            }

            // Checks the height of current elemenents in ElementHeight is greater then pdfHeight.
            if (elementsHeight >= pdfHeight) {
                // Insert Footer
                hidden.innerHTML += `<section class="footer-pdf" style="margin-top: ${
                    pdfHeight - document.getElementById('pageSchedule').offsetHeight
                }px"><h4>AUSCERT</h4><p>conference@auscert.org.au</p></section>`;

                // Takes a picture and save page
                await html2canvas(hidden, { scale: 4 }).then((canvas) => {
                    console.log(`Saving Page ${pageCount}`);
                    const img = canvas.toDataURL('image/png');
                    pdf.addImage(img, 'PNG', 0, 0, 210, (canvas.height * 210) / canvas.width);
                    pdf.addPage();
                });

                elementsHeight = 0;
                pageCount++;
                hidden.innerHTML =
                    '<section class="header-pdf parent-date" style="margin-bottom: 20px"> <div><img src="/wp-content/uploads/2022/02/auscert-2022-light.svg"></div><div><p class="font-degular">CONFERENCE 2022</p><h3 class="font-degular">Rethink, Reskill, Reboot.</h3><h4 class="font-degular">10 - 13 May 2022 / The Star Hotel, Gold Coast</h4></div></section>';
                hidden.innerHTML +=
                    '<tr class="table-header"><th class="time font-degular">Time</th> <th class="session font-degular">Session</th> <th class="location font-degular">Location</th></tr>';
                // Insert current time here
                elementsHeight += hidden.clientHeight * 2;
            }

            hidden.innerHTML += timeslot.outerHTML;
        }

        hidden.innerHTML += `<section class="footer-pdf" style="margin-top: ${
            pdfHeight - document.getElementById('pageSchedule').offsetHeight
        }px"><h4>AUSCERT</h4><p>conference@auscert.org.au</p></section>`;

        await html2canvas(hidden, { scale: 4 }).then((canvas) => {
            const img = canvas.toDataURL('image/png');
            pdf.addImage(img, 'PNG', 0, 0, 210, (canvas.height * 210) / canvas.width);
        });

        await pdf.save('AUSCERT2022-Itinerary.pdf');

        // Removes Spinner
        downloadBtn.innerHTML =
            '<span class="d-none d-sm-inline">Download</span> <span>Full Program</span>';

        // Enable Button
        downloadBtn.disabled = false;
    };
}

// Download Custom Program Button
if (itineraryForm) {
    if (itineraryForm.attachEvent) {
        itineraryForm.attachEvent('submit', processForm);
    } else {
        itineraryForm.addEventListener('submit', processForm);
    }

    async function processForm(e) {
        let schedule = document.getElementById('hiddenSchedule');
        let hidden = document.getElementById('pageSchedule');
        let downloadBtn = document.getElementById('downloadItinerary');
        let pdf = new jsPDF('p', 'mm', 'a4', true);
        let pdfHeight = 742.81; // Size of an A4 PDF (https://www.papersizes.org/a-sizes-in-pixels.htm) @ 72 PPI minus the footer
        let elementsHeight = 0; // Height of elements on that page
        let pageCount = 0; // Count Pages
        let currentPage = false;

        downloadBtn.disabled = true;

        // Insert Spinner
        downloadBtn.innerHTML =
            '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>';

        // Clear the previous schedule just incase they want to generate multiple schedules
        schedule.innerHTML = '';
        hidden.innerHTML = '';

        if (e.preventDefault) e.preventDefault();

        // Gets an array of name fields
        const data = Object.fromEntries(new FormData(e.target).entries());

        let olddate = '';
        let morningAfternoon = '';
        let firstPage = true;

        for (const id in data) {
            let divID =
                id + document.querySelector(`input[name="${id}"]:checked`).getAttribute('stream');
            let dateAndTime = document
                .getElementById(divID)
                .querySelector('.time')
                ?.innerHTML?.split(' ');

            let title = document.getElementById(divID).querySelector('.talk-title')?.innerText;
            let speaker = document.getElementById(divID).querySelector('.talk-speaker')?.innerText;
            let location = document
                .getElementById(divID)
                .querySelector('.stream-container')
                ?.innerText.split('•');
            let badges = document.getElementById(divID).querySelector('.badges')?.innerText;
            let programTitle = document
                .getElementById(divID)
                .querySelector('.schedule-title')?.innerText;

            if (dateAndTime) {
                // Insert Date Header
                let date = dateAndTime[0];
                let time = dateAndTime[1];
                let ampm = dateAndTime[2];

                // Insert Parent Date
                if (morningAfternoon != ampm) {
                    morningAfternoon = ampm;
                    if (morningAfternoon == 'pm') {
                        schedule.innerHTML += `<tr class="table-header"><th class="time afternoon">AFTERNOON</th></tr>`;
                    }
                }

                if (olddate != date) {
                    olddate = date;
                    schedule.innerHTML +=
                        '<section class="header-pdf parent-date"> <div><img src="/wp-content/uploads/2022/02/auscert-2022-light.svg"></div><div><p class="font-degular">CONFERENCE 2022</p><h3 class="font-degular">Rethink, Reskill, Reboot.</h3><h4 class="font-degular">10 - 13 May 2022 / The Star Hotel, Gold Coast</h4></div></section>';
                    schedule.innerHTML += `<h4 class="day-header font-degular">ITINERARY ${date} - ${programTitle}</h4>`;
                    schedule.innerHTML += `<tr class="table-header"><th class="time font-degular morning">MORNING</th></tr>`;
                    schedule.innerHTML +=
                        '<tr class="table-header"><th class="time font-degular">Time</th> <th class="session font-degular">Session</th> <th class="location font-degular">Location</th></tr>';
                }

                // Inserts Table Row & Columns
                if (title) {
                    schedule.innerHTML += `<tr class="table-body font-degular"><td class="time">${
                        time + ' ' + ampm
                    }</td> <td class="session">Topic:<br> <p>${title}</p> <br> Category:<br> <p>${capitalize(
                        badges
                    )}</p> <br> Speakers:<br> <p>${capitalize(
                        speaker
                    )}</p> </td> <td class="location">${capitalize(location[1])}</td> </tr>`;
                } else {
                    schedule.innerHTML += `<tr class="table-body font-degular"><td class="time">${
                        time + ' ' + ampm
                    }</td> <td class="topic">End of AM tutorials • Lunch break</td> <td></td></tr>`;
                }
            }
        }

        for (timeslot of schedule.children) {
            // Assigining the height of the div elements height
            elementsHeight += parseInt(timeslot.clientHeight);

            // Checks if the element is a date adding new page
            if (timeslot.classList.contains('parent-date')) {
                if (firstPage == false) {
                    hidden.innerHTML += `<section class="footer-pdf" style="margin-top: ${
                        pdfHeight - document.getElementById('pageSchedule').offsetHeight
                    }px"><h4>AUSCERT</h4><p>conference@auscert.org.au</p></section>`;
                    await html2canvas(hidden, { scale: 4 }).then((canvas) => {
                        !production() && console.log(`Saving Page ${pageCount}`);
                        const img = canvas.toDataURL('image/png');
                        pdf.addImage(img, 'PNG', 0, 0, 210, (canvas.height * 210) / canvas.width);
                        pdf.addPage();
                    });

                    elementsHeight = 0;
                    pageCount++;
                    hidden.innerHTML = '';
                }
                firstPage = false;
            }

            // Checks the height of current elemenents in ElementHeight is greater then pdfHeight.
            if (elementsHeight >= pdfHeight) {
                // Insert Footer
                hidden.innerHTML += `<section class="footer-pdf" style="margin-top: ${
                    pdfHeight - document.getElementById('pageSchedule').offsetHeight
                }px"><h4>AUSCERT</h4><p>conference@auscert.org.au</p></section>`;

                // Takes a picture and save page
                await html2canvas(hidden, { scale: 4 }).then((canvas) => {
                    !production() && console.log(`Saving Page ${pageCount}`);
                    const img = canvas.toDataURL('image/png');
                    pdf.addImage(img, 'PNG', 0, 0, 210, (canvas.height * 210) / canvas.width);
                    pdf.addPage();
                });

                elementsHeight = 0;
                pageCount++;
                hidden.innerHTML =
                    '<section class="header-pdf parent-date" style="margin-bottom: 20px"> <div><img src="/wp-content/uploads/2022/02/auscert-2022-light.svg"></div><div><p class="font-degular">CONFERENCE 2022</p><h3 class="font-degular">Rethink, Reskill, Reboot.</h3><h4 class="font-degular">10 - 13 May 2022 / The Star Hotel, Gold Coast</h4></div></section>';
                hidden.innerHTML +=
                    '<tr class="table-header"><th class="time font-degular">Time</th> <th class="session font-degular">Session</th> <th class="location font-degular">Location</th></tr>';
                // Insert current time here
                elementsHeight += hidden.clientHeight * 2;
            }

            hidden.innerHTML += timeslot.outerHTML;
        }

        hidden.innerHTML += `<section class="footer-pdf" style="margin-top: ${
            pdfHeight - document.getElementById('pageSchedule').offsetHeight
        }px"><h4>AUSCERT</h4><p>conference@auscert.org.au</p></section>`;

        // Saves a picture of the final page
        await html2canvas(hidden, { scale: 4 }).then((canvas) => {
            const img = canvas.toDataURL('image/png');
            pdf.addImage(img, 'PNG', 0, 0, 210, (canvas.height * 210) / canvas.width);
        });

        // Saves the PDF
        await pdf.save('AUSCERT2022-Itinerary.pdf');

        // Removes Spinner
        downloadBtn.innerHTML = `<span class="d-none d-sm-inline">Download</span> <span>Itinerary</span> • <span id="selectedCount" class="">${programSelectedCount}</span>`;

        // Enable Button
        downloadBtn.disabled = false;

        return false;
    }
}
